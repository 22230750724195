import HttpRequest from '@/utils/request'
// 个人认标，按部门评选
export function log1_depart(data) {
	return HttpRequest({ url: '/member/log1_depart', data })
}

// 个人认标，按岗位评选
export function log1_work(data) {
	return HttpRequest({ url: '/member/log1_work', data })
}

// 个人奖
export function log2_person(data) {
	return HttpRequest({ url: '/member/log2_person', data })
}

// 汇总
export function all(data) {
	return HttpRequest({ url: '/member/all', data })
}



// 部门列表，不分页，搜索使用
export function depart_list(data) {
	return HttpRequest({ url: '/member/depart_list', data })
}


// 部门列表，不分页，搜索使用
export function work_list(data) {
	return HttpRequest({ url: '/member/work_list', data })
}

// PK擂台
export function log2_depart(data) {
	return HttpRequest({ url: '/member/log2_depart', data })
}






