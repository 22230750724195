import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: login
  // },
  // {
  //   path: '/reg',
  //   name: 'reg',
  //   component: ()=>import('@/views/reg.vue')
  // },
  // {
  //   path: '/forget',
  //   name: 'forget',
  //   component: ()=>import('@/views/forget.vue')
  // },
  {
    path: '/unit',
    name: 'unit',
    component: () => import('@/views/list.vue')
  },
  {
    path: '/discussion',
    name: 'discussion',
    component: () => import('@/views/details.vue')
  },
  {
    path: '/Summary',
    name: 'Summary',
    component: () => import('@/views/mine.vue')
  },


]

const router = new VueRouter({
  history: 'hash', // 使用 hash 模式
  routes
})

export default router
