import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import headerTab from  '@/components/headerTab.vue'
import './utils/rem.js'; //在main.js中引入rem.js
import './utils/style.css'
import '@/router/permission'
import { parseTime } from '@/utils/index'
import {routerGo} from '@/utils/index'
Vue.config.productionTip = false
Vue.component('headerTab', headerTab)
Vue.prototype.$routerGo = routerGo
Vue.prototype.$parseTime= parseTime

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


