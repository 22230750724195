<template>
  <div class="home">
    <div class="home_tip">
      — 2024-2025年黄州区邮政代理金融“破局蝶变·剑指巅峰”跨赛一阶段竞标大会 —
      <!-- <img src="@/assets/image/text.png" alt=""> -->
    </div>
    <div class="title flex_aling_justify_center">
      个人摘标榜
    </div>
    <div class="list ">
      <div class="list_item">
        <!-- <div class="subtitle flex_aling_justify_center">
          这是标题这是标题
        </div> -->
        <div class="list_item_select flex_aling_center   flex_js_sb">
          <div>
            按部门/网点： <el-select @change="selectChange" v-model="form.depart_name" placeholder="请选择">
              <el-option v-for="item in options" :label="item.depart_name" :value="item.depart_name">
              </el-option>
            </el-select>
          </div>
          <div class="zongji">
            合计摘标： {{ price }} 万
          </div>
        </div>
        <div class="list_table">
          <div class="list_table_c">
            <div class="table_header flex flex_js_sb ">
              <div class="table_lable flex_aling_justify_center">姓名</div>
              <!-- <div class="table_lable flex_aling_justify_center">标签</div> -->
              <div class="table_lable flex_aling_justify_center">一阶段摘标</div>
              <div class="table_lable flex_aling_justify_center">国庆前7天摘标</div>
              <div class="table_lable flex_aling_justify_center">国庆后7天摘标</div>
            </div>
            <div class="list_table_scroll">

              <template v-if="list.length>0">
                <div class="list_table_item flex_js_sb flex_aling_center" v-for="(item,index) in list" :key="index">
                  <div class="num flex_aling_justify_center">
                    {{ item.name }}
                  </div>
                  <!-- <div class="num work_name   flex_aling_justify_center">
                    {{ item.work_name }}
                  </div> -->
                  <div class="num flex_aling_justify_center">
                    {{ item.value1 }}
                  </div>
                  <div class="num flex_aling_justify_center">
                    {{ item.value2 }}
                  </div>
                  <div class="num flex_aling_justify_center">
                    {{ item.value3 }}
                  </div>
                </div>
              </template>
              <div class="emptyData" v-else>
                <el-empty description="暂无数据"></el-empty>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div class="list_item">
        <!-- <div class="subtitle flex_aling_justify_center">
          这是标题这是标题
        </div> -->
        <div class="list_item_select  flex_aling_center">
          按分组：
          <el-select v-model="form.work_name" placeholder="请选择" @change="selectChange1">
            <el-option v-for="item in options1" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

        </div>
        <div class="list_table">
          <div class="list_table_c">
            <div class="table_header flex flex_js_sb ">
              <div class="table_lable flex_aling_justify_center">姓名</div>
              <!-- <div class="table_lable flex_aling_justify_center">标签</div> -->
              <div class="table_lable flex_aling_justify_center">一阶段摘标</div>
              <div class="table_lable flex_aling_justify_center">国庆前7天摘标</div>
              <div class="table_lable flex_aling_justify_center">国庆后7天摘标</div>
            </div>
            <template v-if="list1.length>0">
              <div class="list_table_scroll">
                <div class="list_table_item flex_js_sb flex_aling_center" v-for="(item,index) in list1" :key="index">
                  <div class="num flex_aling_justify_center">
                    {{ item.name }}
                  </div>
                  <!-- <div class="num flex_aling_justify_center">
                    {{ item.work_name }}
                  </div> -->
                  <div class="num flex_aling_justify_center">
                    {{ item.value1 }}
                  </div>
                  <div class="num flex_aling_justify_center">
                    {{ item.value2 }}
                  </div>
                  <div class="num flex_aling_justify_center">
                    {{ item.value3 }}
                  </div>
                </div>

              </div>
            </template>
            <div class="emptyData" v-else>
              <el-empty description="暂无数据"></el-empty>
            </div>

          </div>

        </div>

      </div>
    </div>
    <headerTab></headerTab>

  </div>
</template>

<script>
import {
  log1_depart,
  log1_work,
  log2_person,
  all,
  depart_list,
  work_list,
} from "@/utils/network.js";
export default {
  data() {
    return {
      textarea: "",
      goods_list: [],
      list: [],
      list1: [],
      options: [],
      options1: [
        { label: "第一组", value: "第一组" },
        { label: "第二组", value: "第二组" },
        { label: "第三组", value: "第三组" },
        { label: "第四组", value: "第四组" },
        { label: "第五组", value: "第五组" },
        { label: "第六组", value: "第六组" },
        { label: "第七组", value: "第七组" },
        { label: "第八组", value: "第八组" },
      ],
      heightScroll: [],
      banner_list: [],
      currentIndex: 4,
      queryParams: {
        page: 1,
        limit: 10,
      },
      form: {
        depart_name: "",
        work_name: "",
      },

      isImage: false,
      price: 0,
    };
  },
  created() {
    this.getldepartlist();
    this.getlworklist();
  },

  methods: {
    selectChange(e) {
      this.getlist(e);
    },
    selectChange1(e) {
      this.getlist1(e);
    },
    async getlist(e) {
      const res = await log1_depart({ depart_name: e });
      this.list = res.data.res;
      this.price = res.data.price;
    },
    async getlist1(e) {
      const res = await log1_work({ zu_name: e });
      this.list1 = res.data;
    },
    async getldepartlist() {
      const res = await depart_list();
      this.options = res.data;
      this.form.depart_name = res.data[0].depart_name;
      this.getlist(this.form.depart_name);
    },
    async getlworklist() {
      const res = await work_list();
      // this.options1 = res.data;
      // this.form.work_name = res.data[0].work_name;
      this.form.work_name = this.options1[0].value;
      this.getlist1(this.form.work_name);
    },
  },
};
</script>


<style   lang="scss" scoped>
.home_tip {
  margin-top: 100px;
  img {
    height: 48px;
  }
}
.list {
  min-height: 70vh;
  align-items: center;
  // background-color: #d90003;
  display: flex;
  padding: 0 60px;
  justify-content: space-around;
  .list_item {
    background: #fff;
    border-radius: 20px 20px 20px 20px;
    padding: 30px 20px;
    .subtitle {
      width: 463px;
      height: 90px;
      background: url("~@/assets/image/subtitle.png");
      background-size: cover;
      padding-top: 20px;
      font-weight: 700;
      font-size: 22px;
      color: #ffffff;
    }
    .list_item_select {
      margin: 20px 0;
      font-size: 25px;
    }
    .list_table {
      margin-top: 20px;
      width: 1050px;
      height: 600px;
      background: linear-gradient(180deg, #fffbf4 0%, #fff9de 100%);
      border-radius: 20px 20px 20px 20px;
      padding: 20px;
      .list_table_c {
        padding: 20px;
        height: 100%;
        border-radius: 20px 20px 20px 20px;
        background: linear-gradient(180deg, #fff8da 0%, #fee5b6 100%);
        border: 2px solid #d90003;
      }
      .table_header {
        .table_lable {
          height: 44px;
          background: #d90003;
          border-radius: 10px 10px 10px 10px;
          width: 160px;
          font-size: 23px;
          color: #ffffff;
        }
      }
      .list_table_scroll {
        max-height: 420px;
        overflow: auto;
        .list_table_item {
          height: 60px;
          display: flex;
          border-bottom: 1px solid #d90003;
          .num {
            width: 160px;
            height: 44px;
            font-size: 25px;
            color: #d90003;
          }
          .work_name {
            width: 180px;
          }
        }
      }
    }
  }
}

.emptyData {
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 覆盖Element UI el-select组件的输入框字体大小 */
::v-deep .el-select .el-input__inner {
  font-size: 20px; /* 你可以根据需要修改这里的字体大小 */
}
::v-deep .el-empty__description p {
  font-size: 30px !important;
}
</style>
