<template>
  <div class="pointer flex_aling_center  headerTab ">
    <img class="zuoyou " src="@/assets/image/zuoyou.gif" alt="" />
    <div class="tabs  flex_aling_center   felx_js_sb">

      <div class="tabs_item flex_al_js_center" @click="goPage('/')">个人摘标榜</div>
      <div class="tabs_item flex_al_js_center" @click="goPage('/unit')">PK擂台赛 </div>
      <div class="tabs_item flex_al_js_center" @click="goPage('/discussion')">获奖名单</div>
      <div class="tabs_item flex_al_js_center" @click="goPage('/Summary')">摘标汇总</div>

    </div>
    <img class="zuoyou" src="@/assets/image/zuoyou.gif" alt="" />
  </div>
</template>

<script>
export default {
  methods: {
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      this.$routerGo(path);
    },
  },
  computed: {
    isLogin() {
      return localStorage.getItem("userInfo") ? true : false;
    },
  },
};
</script>

<style  scoped lang="scss">
.tabs {
  margin: 20px auto;
  width: 80%;
  .tabs_item {
    width: 220px;
    height: 60px;
    background: linear-gradient(360deg, #bc2424 24%, #ff372e 95%);
    border-radius: 30px 30px 30px 30px;
    border: 2px solid #fee8bb;

    font-size: 26px;
    color: #fee8bb;
  }
}
.zuoyou {
  width: 200px;
  height: 200px;
}
</style>